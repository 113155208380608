<template>
  <div class="box">
    <h1 class="header">
      Privacy Policy
    </h1>
    <h3 class="text">
      The privacy policy is simple: no personal data shared with us will be given to any third party. In fact,
      it is physically impossible as we have nowhere to store it. We do not have a server database for data storage.
      Everything that happens on this app stays on this app. The app engages in no ad targeting, data mining, or other activities that may compromise your privacy,
      and we do not affiliate ourselves with any third parties that do so.
    </h3>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.app{
  max-width: 80rem;
  margin: auto;
}

.header{
  text-align: center;
  margin: auto;
  margin-top: 5rem;
  font-family: Arial, Helvetica, sans-serif;
}

.text{
  text-align: center;
  margin: auto;
  margin-top: 2rem;
  max-width: 80rem;
  font-family: Arial, Helvetica, sans-serif;

}

</style>